import React, { useState } from 'react';
import {
  TableContainer, Table, TableRow,
  TableCell, TableHead, TableBody,
  Button, Typography
} from '@material-ui/core';
import {
  GetApp as GetAppIcon,
  Notes as NotesIcon,
  SystemUpdate as InstallIcon
} from '@material-ui/icons';
import ReleaseNotesModal from "../components/ReleaseNotesModal";
import { downloadFile, downloadApp } from "../services/Download";

const DownloadTab = ({
  title = "",
  data,
  release = false,
}) => {
  const isApp = data.some(item => item.type === "app");
  const [displyNotesModal, setDisplayNotesModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  return (
    <>
      <TableContainer style={{ margin: "2rem 0" }}>
        {title && <Typography variant="h6">{title}</Typography>}
        <Table>
          <TableHead >
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }} width="10%">File</TableCell>
              <TableCell style={{ fontWeight: "bold" }} width="10%">Version</TableCell>
              <TableCell style={{ fontWeight: "bold" }} width="50%">Created</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center" width="10%">{release && "Release Notes"}</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center" width="10%">Download</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center" width="10%">{isApp && "Install to iPad"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(file => (
              <TableRow key={file.key}>
                <TableCell>{file.app}</TableCell>
                <TableCell>{file.version}</TableCell>
                <TableCell>{file.created}</TableCell>
                <TableCell align="center">
                  {release && file?.releaseNote &&
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        setSelectedNote(file.releaseNote.key);
                        setDisplayNotesModal(true);
                      }}>
                      <NotesIcon />
                    </Button>
                  }
                </TableCell>

                <TableCell align="center">
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      downloadFile(
                        file.type === "app"
                          ? `${file.key}.ipa`
                          : file.key
                      )
                    }>
                    <GetAppIcon />
                  </Button>
                </TableCell>

                <TableCell align="center">
                  {isApp &&
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() =>
                        file.type === "app"
                          ? downloadApp(`${file.key}.plist`)
                          : downloadFile(file.key)
                      }>
                      <InstallIcon />
                    </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {displyNotesModal && selectedNote &&
        <ReleaseNotesModal
          open={displyNotesModal}
          onClose={() => {
            setSelectedNote(null);
            setDisplayNotesModal(false);
          }}
          notePath={selectedNote}
        />
      }
    </>
  );
}
export default DownloadTab;