import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import {
  Paper,
  createMuiTheme,
  MuiThemeProvider,
  CssBaseline,
} from '@material-ui/core'
import { DownloadsContextProvider } from "./context/DownloadsContext";
import Login from './components/Login';
import AppHeader from './components/AppHeader';
import DownloadContainer from './components/DownloadContainer';

import styles from './styles';
import './App.css';

const theme = createMuiTheme({
  typography: {
    body1: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#3e90c7",
    },
    secondary: {
      main: "#fff",
    },
  },
});

const App = () => {
  const [error, setError] = useState(null);
  const [verified, setVerified] = useState(false);

  useEffect(() => checkCurrentUser(), []);

  const handleLogout = async () =>
    Auth.signOut({ global: true })
      .then(() => setVerified(false));

  const checkCurrentUser = async () =>
    Auth.currentSession()
      .then(() => setVerified(true))
      .catch(err => console.error(err));

  return (
    <div style={styles.app.root}>
      <CssBaseline>
        <MuiThemeProvider theme={theme}>
          <AppHeader
            userVerified={verified}
            handleLogout={handleLogout}
          />
          <div style={styles.app.container}>
            {!verified &&
              <Login
                handleLoginSuccess={() => setVerified(true)}
              />
            }
            {verified &&
              <DownloadsContextProvider>
                <DownloadContainer />
              </DownloadsContextProvider>
            }
            {error &&
              <Paper>
                <p style={styles.error}>{error}</p>
              </Paper>
            }
          </div>
        </MuiThemeProvider>
      </CssBaseline>
    </div>
  );
}

export default App;
