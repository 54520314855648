const styles = {
    app: {
        root: {
            textAlign: 'center',
        },
        header: {
            backgroundColor: '#3e90c7',
            padding: '1em',
            height: '2em',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flexStart',
            color: 'white',
            fontSize: '2rem',
            logo: {
                height: '2em',
                marginRight: '2em',
            },
            content: {
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }
        },
        container: {
            padding: '2em'
        }
    },
    downloadContainer: {
        paddingLeft: "1em",
        paddingRight: "1em"
    },
    login: {
        root: {
            display: 'flex',
            justifyContent: 'center'
        },
        dialog: {
            width: '25rem',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    supportDocs: {
        marginTop: '3rem'
    },
    enabled: {
        backgroundColor: '#3e90c7',
        color: 'white'
    },
    disabled: {
        backgroundColor: 'lightGray',
        color: 'white'
    },
    error: {
        fontSize: '1rem',
        color: 'red'
    }
}
export default styles;