import Amplify from '@aws-amplify/core';

export function configureAmplify() {
    Amplify.configure({
        Auth: {
            identityPoolId: 'eu-west-2:15384e65-860e-411b-87f2-a386dd5f82f0', //REQUIRED - Amazon Cognito Identity Pool ID
            region: 'eu-west-2', // REQUIRED - Amazon Cognito Region
            userPoolId: 'eu-west-2_qFTNPZ71b', //OPTIONAL - Amazon Cognito User Pool ID
            userPoolWebClientId: '5lngdq2v10rk4k6t6soi6uhvud', //OPTIONAL - Amazon Cognito Web Client ID
        },
        Storage: {
            AWSS3: {
                bucket: 'modern-democracy-app-downloads', //REQUIRED -  Amazon S3 bucket name
                region: 'eu-west-2', //OPTIONAL -  Amazon service region
            }
        }
    });
}