import React from 'react';
import { Button, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import styles from '../styles';
import { downloadFile } from "../services/Download";

const AssetsContainer = ({ assets }) => (
  <div style={styles.supportDocs}>
    {assets.length > 0 &&
      <>
        <Typography variant="h6">Assets</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ fontWeight: "bold" }} width="70%">Asset</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center" width="30%">Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets.map((image, index) => (
              <TableRow key={image.key}>
                <TableCell>{++index}</TableCell>
                <TableCell>{image.name}</TableCell>
                <TableCell align="center">
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => downloadFile(image.key)}>
                    <GetAppIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    }
  </div>
);

export default AssetsContainer;