const getFileAndDate = (item) => {
  if (item.lastModified && item.key) {
    const date = new Date(item.lastModified).toLocaleString();
    const [, file] = item.key.split("/");
    return { date, file };
  }
}

export const formatReleaseData = (apps = [], notes = []) =>
  apps.map(item => {
    const { date, file } = getFileAndDate(item);
    const [app, , version] = file.split("_");

    const note = notes.find(
      note => note.key.includes(version)
    );

    return {
      key: `Release-Versions/${app}_version_${version}/${app}`,
      app,
      version,
      created: date,
      type: "app",
      releaseNote: note || ""
    }
  })

export const formatAppData = (apps = [], notes = []) =>
  apps.map(item => {
    const { date, file } = getFileAndDate(item);
    const [app, , version] = file.split("_");

    const note = notes.find(
      note => note.key.includes(version)
    );

    return {
      key: `${app}/${app}_version_${version}/${app}`,
      app,
      version,
      created: date,
      type: "app",
      releaseNote: note || ""
    }
  })


export const formatWelshData = (apps = [], notes = []) =>
  apps.map(item => {
    const { date, file } = getFileAndDate(item);
    const [app, , version] = file.split("_");

    const note = notes.find(
      note => note.key.includes(version)
    );

    return {
      key: `Welsh-Pilot/${app}_version_${version}/${app}`,
      app,
      version,
      created: date,
      type: "app",
      releaseNote: note || ""
    }
  });

export const formatSupportData = (docs = []) =>
  docs.map(doc => {
    const { date, file } = getFileAndDate(doc);
    return {
      key: doc.key,
      name: file.split("-")[1],
      created: date,
      type: "document"
    }
  });

export const formatConfigData = (configs = []) =>
  configs.map(item => {
    const { date, file } = getFileAndDate(item);
    const [app, , version] = file.split("_");
    return {
      key: item.key,
      app,
      version: version.split(".")[0],
      created: date,
      type: "config"
    }
  });

export const formatAssetsData = (assets = []) =>
  assets.map(asset => {
    const { date, file } = getFileAndDate(asset);
    return {
      key: asset.key,
      name: file,
      created: date,
      type: "image"
    }
  });

export const alphaNumSort = (a, b) => a.key.localeCompare(
  b.key,
  navigator.languages[0] || navigator.language,
  { numeric: true, ignorePunctuation: true }
);

export const getAppFiles = (files = []) =>
  files.filter(item => item.key.endsWith(".ipa"))
    .sort(alphaNumSort)
    .reverse()
    .slice(0, 20);

export const getReleaseNotes = (files = []) =>
  files.filter(item => item.key.endsWith(".md"))
    .sort(alphaNumSort)
    .reverse()
    .slice(0, 20)

