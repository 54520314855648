import React, { useContext, useState } from "react";
import { Paper, Box, Tabs, Tab, Typography, CircularProgress } from "@material-ui/core";
import { Home, Notes, TabletMac, SettingsApplications } from "@material-ui/icons";
import { DownloadsContext } from "../context/DownloadsContext";

import DownloadTab from "./DownloadTab";
import ReleaseNotesTab from "./ReleaseNotesTab";
import DocumentContainer from "./DocumentContainer";
import AssetsContainer from "./AssetsContainer";
import styles from "../styles";

const DownloadContainer = () => {
  const [selectedTab, setTab] = useState(0);

  const {
    fetchingContents,
    currentVersions,
    mpDownloads,
    releaseDownloads,
    iPadConfigDownloads,
    supportDocDownloads,
    assetsDownloads,
    welshDownloads
  } = useContext(DownloadsContext);
  const boldFont = { fontWeight: "bold" };
  return (
    <Paper style={styles.downloadContainer} elevation={10}>
      {fetchingContents && (
        <div style={{ padding: "3rem" }}>
          <Typography variant="h6">Getting Files...</Typography>
          <CircularProgress size={60} style={{ color: '#3e90c7' }} />
        </div>
      )}
      {!fetchingContents && (
        <>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={(_, newValue) => setTab(newValue)}>
            <Tab style={boldFont} icon={<Home />} label="Home" />
            <Tab style={boldFont} icon={<TabletMac />} label="Modern Polling Builds" />
            <Tab style={boldFont} icon={<TabletMac />} label="Welsh Pilot Builds" />
            <Tab style={boldFont} icon={<Notes />} label="Change Log" />
            <Tab style={boldFont} icon={<SettingsApplications />} label="iPad Configurations" />
          </Tabs>

          <TabPanel index={0} value={selectedTab}>
            <DownloadTab
              title={"Release Versions"}
              data={releaseDownloads}
              release
            />
            <DownloadTab
              title={"Recent Versions"}
              data={currentVersions}
            />
            <DocumentContainer
              documents={supportDocDownloads}
            />
            <AssetsContainer
              assets={assetsDownloads}
            />
          </TabPanel>

          <TabPanel index={1} value={selectedTab}>
            <DownloadTab
              data={mpDownloads}
            />
          </TabPanel>
          <TabPanel index={2} value={selectedTab}>
            <DownloadTab
              data={welshDownloads}
            />
          </TabPanel>
          <TabPanel index={3} value={selectedTab}>
            <ReleaseNotesTab />
          </TabPanel>

          <TabPanel index={4} value={selectedTab}>
            <DownloadTab
              data={iPadConfigDownloads}
            />
          </TabPanel>
        </>
      )}
    </Paper>
  );
}

const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index}>
    {value === index &&
      <Box p={0}>{children}</Box>
    }
  </div>
);

export default DownloadContainer;