import React from 'react';
import { Button, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import styles from '../styles';
import { downloadFile } from "../services/Download";

const DocumentContainer = ({ documents = [] }) => (
  <div style={styles.supportDocs}>
    <Typography variant="h6">Support Documentation</Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell style={{ fontWeight: "bold" }} width="70%">Document</TableCell>
          <TableCell style={{ fontWeight: "bold" }} align="center" width="30%">Download</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {documents.map((doc, index) => (
          <TableRow key={doc.key}>
            <TableCell>{++index}</TableCell>
            <TableCell>{doc.name}</TableCell>
            <TableCell align="center">
              <Button
                size="small"
                variant="outlined"
                onClick={() => downloadFile(doc.key)}>
                <GetAppIcon />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div >
);

export default DocumentContainer;