import { Storage } from 'aws-amplify';
Storage.configure({ level: 'public' });

const DOMAIN = "https://modern-democracy-app-downloads.s3.eu-west-2.amazonaws.com/public";

const getDownloadName = (path) => {
  const splitPath = path.split("/");
  const name = splitPath[splitPath.length - 1];
  return name;
}

const downloadToSystem = (downloadURL, downloadName, appInstall = false) => {
  const link = document.createElement('a');
  link.href = downloadURL;
  if (!appInstall) {
    link.download = downloadName || "download";
  }
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

export const downloadFile = async (path) => {
  try {
    const res = await Storage.get(path, { download: true });
    const downloadURL = URL.createObjectURL(res.Body);
    const downloadName = getDownloadName(path);
    downloadToSystem(downloadURL, downloadName);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const downloadApp = (path) => {
  const downloadURL = `itms-services://?action=download-manifest&url=${DOMAIN}/${path}`;
  const downloadName = getDownloadName(path);
  downloadToSystem(downloadURL, downloadName, true);
}