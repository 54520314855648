import React, { useState, useEffect } from 'react';
import ReactMarkdown from "react-markdown";
import { Modal, Typography, CircularProgress } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { downloadNote } from "../services/Storage";
import "../styles/modal.css";

const ReleaseNotesModal = ({
  width = "70%",
  height = "70%",
  open,
  onClose,
  notePath
}) => {
  const [loading, setLoading] = useState(false);
  const [noteContent, setNotesContent] = useState(null);

  const getNoteContent = async () => {
    try {
      setLoading(true);
      const note = await downloadNote(notePath);
      setNotesContent(note);
    } catch (error) {
      console.error(`Error getting note content: ${error?.message}`);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getNoteContent();
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="c-modal" style={{ width, height }}>
        <div className="c-modal__head">
          <Typography variant="h6">Release Notes</Typography>
          <div className="+right" style={{ cursor: "pointer" }}>
            <Close
              fontSize="large"
              onClick={onClose}
              style={{ color: "white" }}
            />
          </div>
        </div>
        <div className="c-modal__body">
          {loading &&
            <div className="modal__loader">
              <CircularProgress size={60} style={{ color: '#3e90c7' }} />
            </div>
          }
          {noteContent && !loading &&
            <div style={{ textAlign: "left", padding: "1rem 5rem" }}>
              <ReactMarkdown children={noteContent} />
            </div>
          }
        </div>
        <div className="c-modal__footer"></div>
      </div>
    </Modal >
  )
}

export default ReleaseNotesModal
