import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Paper, TextField } from '@material-ui/core';
import styles from '../styles';

const Login = props => {
    const { handleLoginSuccess } = props
    const [usernameVal, setUsername] = useState("");
    const [passwordVal, setPassword] = useState("");
    const [loginEnabled, setEnabled] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (usernameVal && passwordVal) {
            setEnabled(true);
        } else {
            setEnabled(false);
        }
    }, [usernameVal, passwordVal])

    const handleSignIn = async () => {
        try {
            const user = await Auth.signIn(usernameVal, passwordVal);
            if (user) {
                handleLoginSuccess();
            }
        } catch (error) {
            setUsername("");
            setPassword("");
            setError(error.message);
            setTimeout(() => setError(null), 5000);
        }
    }

    return (
        <div style={styles.login.root}>
            <Paper style={styles.login.dialog} elevation={20}>
                <TextField
                    id="username"
                    label="Username"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={usernameVal}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    id="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={passwordVal}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    disabled={!loginEnabled}
                    style={loginEnabled ? styles.enabled : styles.disabled}
                    size="medium"
                    onClick={handleSignIn}>
                    Log In
                </Button>
                {error !== null &&
                    <p style={styles.error}>{error}</p>
                }
            </Paper>
        </div>
    );
}

export default Login;