import React, { createContext, useState, useEffect } from "react";
import { getBucketContents } from "../services/Storage";

export const DownloadsContext = createContext({
  fetchingContents: false,
  currentVersions: [],
  releaseDownloads: [],
  mpDownloads: [],
  iPadConfigDownloads: [],
  supportDocDownloads: [],
  assetsDownloads: [],
  welshDownloads: []
});

const useDownloadsContext = () => {
  const [fetchingContents, setFetchingContents] = useState(false);
  const [currentVersions, setCurrentVersions] = useState([]);
  const [releaseDownloads, setReleaseDownloads] = useState([]);
  const [mpDownloads, setMpDownloads] = useState([]);
  const [iPadConfigDownloads, setiPadConfigDownloads] = useState([]);
  const [supportDocDownloads, setSupportDocDownloads] = useState([]);
  const [assetsDownloads, setAssetsDownloads] = useState([]);
  const [welshDownloads, setWelshDownloads] = useState([]);

  const fetchDownloads = async () => {
    try {
      setFetchingContents(true);
      const contents = await getBucketContents();
      const { releaseVersions, modernPolling, config, support, assets, welsh } = contents;
      Promise.all([
        setReleaseDownloads(releaseVersions),
        setMpDownloads(modernPolling),
        setiPadConfigDownloads(config),
        setSupportDocDownloads(support),
        setAssetsDownloads(assets),
        setCurrentVersions([modernPolling[0], config[0]]),
        setWelshDownloads(welsh)
      ]);
    } catch (error) {
      console.log(`Error getting Downloads: ${error?.message || error}`);
    } finally {
      setFetchingContents(false);
    }
  }

  useEffect(() => {
    fetchDownloads()
  }, []);

  return {
    fetchingContents,
    currentVersions,
    releaseDownloads,
    mpDownloads,
    iPadConfigDownloads,
    supportDocDownloads,
    assetsDownloads,
    welshDownloads
  }
}

export const DownloadsContextProvider = ({
  children
}) => {
  const value = useDownloadsContext();
  return (
    <DownloadsContext.Provider value={value}>
      {children}
    </DownloadsContext.Provider>
  );
};






