import React, { useEffect, useState } from 'react';
import ReactMarkdown from "react-markdown";
import { CircularProgress } from "@material-ui/core";
import { getChangeLog } from "../services/Storage";

const ReleaseNotesTab = () => {
  const [loading, setLoading] = useState(false);
  const [changeLog, setChangeLog] = useState(null);

  const getLog = async () => {
    try {
      setLoading(true);
      const content = await getChangeLog();
      setChangeLog(content);
    } catch (error) {
      console.error(`Error getting note content: ${error?.message}`);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getLog();
  }, []);

  return (
    <div>
      {loading &&
        <div>
          <CircularProgress size={60} style={{ color: '#3e90c7' }} />
        </div>
      }
      {changeLog && !loading &&
        <div style={{ textAlign: "left", padding: "1rem 5rem" }}>
          <ReactMarkdown children={changeLog} />
        </div>
      }
      {!changeLog && !loading &&
        <div><p>No Data</p></div>
      }
    </div>
  )
}

export default ReleaseNotesTab
