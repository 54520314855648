import React from 'react';
import { AppBar, Typography, Toolbar, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from '@material-ui/icons/Lock';

import logo from '../img/MD.webp'
import styles from '../styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
  },
  logo: {
    height: "3em",
    marginRight: "3em",
  },
}));

const AppHeader = ({
  userVerified,
  handleLogout
}) => {
  const classes = useStyles();
  return (
    <AppBar position="sticky" color="primary">
      <Toolbar>
        <img className={classes.logo} src={logo} alt="MD Logo" />
        <Typography className={classes.title} variant="h5">
          Downloads
        </Typography>
        {userVerified && (
          <IconButton color="primary" onClick={handleLogout}>
            <LockIcon color="secondary" />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default AppHeader;
