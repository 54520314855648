import { Storage } from "aws-amplify";
import {
  formatAssetsData,
  formatAppData,
  formatConfigData,
  formatSupportData,
  alphaNumSort,
  getAppFiles,
  getReleaseNotes,
  formatReleaseData,
  formatWelshData
} from "../services/Utils";

Storage.configure({ level: "public" });

const getModernPolling = async () => {
  try {
    const results = await Storage.list("Modern-Polling/");
    const apps = getAppFiles(results);
    const formatted = formatAppData(apps);
    return formatted;
  } catch (error) {
    console.error("Error getting Modern Polling", error);
    throw error;
  }
}

const getReleaseVersions = async () => {
  try {
    const results = await Storage.list("Release-Versions/");
    const apps = getAppFiles(results);
    const notes = getReleaseNotes(results);
    const formatted = formatReleaseData(apps, notes);
    return formatted;
  } catch (error) {
    console.error(`Error getting Release Versions`, error);
    throw error;
  }
}

export const downloadNote = async (path) => {
  try {
    const res = await Storage.get(path, { download: true });
    const content = await res.Body.text();
    return content;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getChangeLog = async () => {
  try {
    const results = await Storage.list("Release-Notes/");
    const note = results.find(res => res.key.endsWith("ChangeLogs.md"));
    const res = await Storage.get(note.key, { download: true });
    const content = await res.Body.text();
    return content;
  } catch (error) {
    console.error("Error getting Release Notes", error);
    throw error;
  }
}

const getiPadConfigs = async () => {
  try {
    const results = await Storage.list("IPad-configurations/");
    const configs = results.filter(item => item.key.endsWith(".mobileconfig")).sort(alphaNumSort);
    const formatted = formatConfigData(configs);
    return formatted;
  } catch (error) {
    console.error("Error getting iPad Configs", error);
    throw error;
  }
}

const getSupportDocuments = async () => {
  try {
    const results = await Storage.list("Support-Documents/");
    const documents = results.filter(item => item.key.endsWith(".docx")).sort(alphaNumSort);
    const formatted = formatSupportData(documents);
    return formatted;
  } catch (error) {
    console.error("Error getting Support Docs", error);
    throw error;
  }
}

const getAssets = async () => {
  try {
    const results = await Storage.list("Assets/");
    const assets = results.filter(item => item.key.endsWith(".png" || ".jpeg")).sort(alphaNumSort);
    const formatted = formatAssetsData(assets);
    return formatted;
  } catch (error) {
    console.error("Error getting Assets", error);
    throw error;
  }
}

const getWelsh = async () => {
  try {
    const results = await Storage.list("Welsh-Pilot/");
    const apps = getAppFiles(results);
    const formatted = formatWelshData(apps);
    return formatted;
  } catch (error) {
    console.error("Error getting Welsh pilot apps");
    throw error;
  }
}

export const getBucketContents = async () => {
  try {
    const [
      releaseVersions,
      modernPolling,
      config,
      support,
      assets,
      welsh
    ] = await Promise.all([
      getReleaseVersions(),
      getModernPolling(),
      getiPadConfigs(),
      getSupportDocuments(),
      getAssets(),
      getWelsh()
    ]);
    return {
      releaseVersions, modernPolling,
      config, support, assets, welsh
    };
  } catch (error) {
    console.error("Error Fetching Contents", error);
    throw error;
  }
}